import { errors } from "@slashid/slashid";
import type { ErrorCode, ErrorState } from ".";

export const errorToErrorCode = async (state: ErrorState): Promise<ErrorCode | undefined> => {
  if (await errors.isRegistrationAlreadyCompleteError(state.error)) {
    return "registration_already_completed"
  }

  if (await errors.isTimeoutError(state.error)) {
    return "authentication_expired"
  }

  return undefined
}