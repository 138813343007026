import { useEffect } from "react"
import { Circle, Spinner, Stack } from "@slashid/react-primitives"
import { errors, type RedirectUriDiscoveredEvent } from "@slashid/slashid"

import type { FlowType } from "./flow.types"
import { Text } from "../text"
import { useAppContext } from "../app/app.context"
import { Recover } from "./flow.recover"
import { logError } from "../../domain/errors"

function Loader() {
  return (
    <Circle variant="primary">
      <Spinner />
    </Circle>
  )
}

export type Props = {
  flowType: FlowType
  onSuccess: () => void
  onError: ({ error }: { error: Error }) => void
  onRedirectDiscovered?: (event: RedirectUriDiscoveredEvent) => void
}

export function Progress({ onSuccess, onError, flowType, onRedirectDiscovered }: Props) {
  const { sdk } = useAppContext()

  useEffect(() => {
    if (!sdk) return

    onRedirectDiscovered && sdk?.subscribe("redirectUriDiscovered", onRedirectDiscovered)

    async function authenticate() {
      try {
        await sdk?.getUserFromURL()
        onSuccess()
      } catch (e: unknown) {
        await logError(e)
        const safeError = await errors.toSlashIDError(e)
        onError({ error: safeError })
      }
    }

    authenticate()

    return () => {
      onRedirectDiscovered && sdk.unsubscribe("redirectUriDiscovered", onRedirectDiscovered)
    }
  }, [flowType, onError, onSuccess, sdk])

  if (flowType === "password-recovery") {
    return <Recover />
  }

  return (
    <>
      <Stack space="0.25">
        <Text as="h1" variant={{ size: "2xl-title", weight: "bold" }} t="initial.title" />
        <Text variant={{ color: "contrast", weight: "semibold" }} as="h2" t="initial.details" />
      </Stack>
      <Loader />
    </>
  )
}
